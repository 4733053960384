import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../core/services/main.service';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-bad-request-page',
  templateUrl: './bad-request-page.component.html',
  styleUrls: ['./bad-request-page.component.scss']
})
export class BadRequestPageComponent implements OnInit {

  constructor(private router:Router,private authService:AuthService) {}

  ngOnInit(): void {
    localStorage.clear()
    // below code is written to navigate from this page to login page on reload/refresh
    this.onReload()

  }
  onReload() {
    this.authService.getWhitelabelImage().subscribe(() => {
      this.router.navigate(['/auth/login']);
    })
  }
}
