// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  encryptSecretKey: '7yerwtur5367tgyt6735473t4yutr37436', 
  // baseURL:'https://api-gateway.btmm222.com/blueWhitelabelAdminBackend', 
  // soketUrl:'https://apiwhitelabel-admin.btmm222.net', 
  // baseURL:'https://apiwhitelabel-admin.btmm222.net', 
  // baseURL: 'https://api-gateway.btmm222.com/whitelabelAdminBackend', 
  // soketUrl: 'http://192.180.0.163:7002', 
  // soketUrl:'https://apiwhitelabel-admin.btmm222.net', 
  // API_KEY:'staging' 
  // baseURL:'http://192.180.0.68:4000', 
  baseURL:'https://api-gateway-uat.btmm222.com/whitelabelAdminBackend', 
  soketUrl:'https://apiwhitelabel-admin-uat.btmm222.com', 
  API_KEY: 'development' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
