import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  //Function to encrypt data.
  encryptData(data: any):any{
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data),environment.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  //Function to decrypt data
  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  //Save data into localStorage after encrypt
  saveDataIntoLocalStorage(key: string, data: any) {
    let encryptedData: string | undefined = this.encryptData(data);
    encryptedData && localStorage.setItem(key, encryptedData);
  }

  //Get data from localstorage after decrypt
  getDataFromLocalstorage(key:string){
    let data:any = localStorage.getItem(key);
    return this.decryptData(data);
  }
}
