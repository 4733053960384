import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuradService } from './core/services/guardService/authGuard.service';
import { MainGuradService } from './core/services/guardService/mainGuard.service';
import { BadRequestPageComponent } from './bad-request-page/bad-request-page.component';


const routes: Routes = [
  {
    path:'',
    redirectTo:'auth',
    pathMatch:'full'
  },
  {
    path:'auth',
    loadChildren:()=> import('./auth-module/auth-module.module').then(m=>m.AuthModuleModule),
    canActivate:[AuthGuradService]
  },
  {
    path:'main',
    data: { breadcrumb: {alias: 'Dashboard', skip: true} },
    loadChildren:()=>import ('./main-module/main-module.module').then(m=>m.MainModuleModule),
    canActivate:[MainGuradService]
  },
  {
    path:'badrequest',
    component:BadRequestPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
