import { Injectable } from "@angular/core";
import { APIS } from "src/constants";
import { HttpService } from "./http.service";
@Injectable({providedIn:'root'})
export class AuthService{
    constructor(private httpService:HttpService){}
    login(data:any){
        return this.httpService.post(APIS.AUTH.LOGIN,data);
    }

    forgotPassword(data:any){
        return this.httpService.post(APIS.AUTH.FORGOT_PASSWORD,data);
    }

    resetPassword(data:any){
        return this.httpService.put(APIS.AUTH.RESET_PASSWORD,data);
    }
    getWhitelabelImage() {
        return this.httpService.get(APIS.MAIN.GET_WHITELABEL_IMAGE)
    }
}