import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SocketService } from "../socket.service";

@Injectable()
export class MainGuradService implements CanActivate{
    socketService = inject(SocketService);
    constructor(private router:Router){}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(this.isAutharized()){
            !this.socketService.socket?.connected && this.socketService.connect();
            return true;
        }
        this.router.navigateByUrl(`/`);
        return false;
    }

    isAutharized():boolean{
        let token = localStorage.getItem('token');
        if(token){
            return true;
        }
        return false;
    }
}