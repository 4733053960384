import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InterceptorService } from './core/services/http.request.interceptor';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MainGuradService } from './core/services/guardService/mainGuard.service';
import { AuthGuradService } from './core/services/guardService/authGuard.service';
import {BreadcrumbModule} from 'xng-breadcrumb';
import { BadRequestPageComponent } from './bad-request-page/bad-request-page.component';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    BadRequestPageComponent,
  ],
  imports: [
    BrowserModule,
    BreadcrumbModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [AuthGuradService,MainGuradService,{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
