
<!-- <a href="https://codepen.io/uiswarup/full/yLzypyY" target="_blank"> -->
    <header class="top-header">
  </header>
  
  <!--dust particel-->
  <div>
    <div class="starsec"></div>
    <div class="starthird"></div>
    <div class="starfourth"></div>
    <div class="starfifth"></div>
  </div>
  <!--Dust particle end--->
  
  
  <div class="lamp__wrap">
    <div class="lamp">
      <div class="cable"></div>
      <div class="cover"></div>
      <div class="in-cover">
        <div class="bulb"></div>
      </div>
      <div class="light"></div>
    </div>
  </div>
  <!-- END Lamp -->
  <section class="error">
    <!-- Content -->
    <div class="error__content">
      <div class="error__message message">
        <h1 class="message__title">You Are Not Whitelisted</h1>
        <p class="message__text">Please Contact To Your Administrator</p>
      </div>
      <!-- <div class="error__nav e-nav">
        <a href="javascript:void(0)" routerLink="/"class="e-nav__link"></a>
      </div> -->
    </div>
    <!-- END Content -->
  
  </section>
  
    <!-- </a> -->