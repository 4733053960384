export const PATHS = {
    AUTH:{
        LOGIN:'login',
        FORGOT_PASSWORD:'forgot-password',
        RESET_PASSWORD:'reset-password/:token'
    },
    MAIN:{
        DASHBOARD:'dashboard',
        PLAYERS:'players',
        PLAYERS_LIST_GAME:'list-game',
        PLAYER_DETAIL:'player-detail',
        DETAIL:'detail',
        GAMES:'games',
        PROVIDER: 'provider',
        GAME_SETTINGS: 'game-settings',
        ACCOUNTS:'accounts',
        CHANGE_LOGS:'change-log',
        RBAC_MANAGEMENT:'rbac-management',
        PLAYER_TRACSATION_HISTORY:'transaction-history',
        REFERRAL_BONUS:'referral-bonus',
        PLAYER_LOGIN_HISTORY:'login-history',
        PLAYER_ALL_TIME_HISTORY:'all-time-history',
        PLAYER_CHANGE_PASSWORD:'change-password',
        TRANSACTIONS:'transactions',
        SETTING:'setting',
        DEPOSIT:'deposit',
        RECURRING_BONUS:'recurring',
        DEPOSIT_BONUS:'deposit-bonus',
        WiITHDRAW:'withdraw',
        ADJUSTMENT:'adjustment',
        REPORTS:'reports',
        FINANCE:'finance',
        BONUS:'bonus',
        GLOBAL: 'global',
        REFERRAL:'referral',
        ROLLINGAN:'rollingan',
        CASHBACK:'cashback',
        ACCOUNT_TYPES:'account-types',
        ACCOUNT_BRAND: 'account-brand',
        ACCOUNT: 'account',
        CONTACT:'contact',
        PLAYER_GAME_HOSTORY:'history',
        CMS: 'cms',
        POPUP_BANNER: 'popup-banner',
        MAIN_BANNNER:'main-banner',
        ABOUT_US:'about-us',
        PERMOTIONS:'permotions',
        RUNNING_TEXT:'running-text',
        FOOTER:'footer',
        HOW_IT_WORK:'how-it-works',
        RESPONSIBLE_GAMING:'responsible-gaming',
        HELP_CENTER:'help-center',
        TERMS_AND_CONDITIONS:'terms-and-conditions',
        SITE_DATA:'site-data',
        THANKYOU:'thank-you',
        REPORT:'report',
        TRANSACTION_REPORT:'transaction-report',
        GAME_WIN_LOSE:'game-win-lose',
        PLAYER_REPORT:'player-report',
        PLAYER_WIN_LOSE:'player-win-lose',
        PROVIDER_WIN_LOSE:'provider-win-lose',
    }
};

export const REGEX = {
    USERNAME: /[a-zA-Z0-9]{3,}/,
    EMAIL: /[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}/,
    PASSWORD: /^(?=.*[a-zA-Z!@#\$%\^&\*])(?=.*[0-9]).{6,}$/,
    CPF:/\d{3}\.\d{3}\.\d{3}\-\d{2}/,
    YOUTUBE_LINK:/^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/,
    PHONE:/^\+\d{1,3}\d{8,10}$/,
    DOMAIN:/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
    ACCOUNT_NUMBER:/^[0-9]{9,18}$/,
    THOUSAND_SEPARATOR: /^[-+]?\d{1,3}(?:,\d{3})*(?:\.\d+)?$/
};
export const APIS = {
    AUTH:{
        LOGIN:'/v1/admin/login',
        FORGOT_PASSWORD:'/v1/admin/password/forgot',
        RESET_PASSWORD:'/v1/admin/reset',
        REFRESH_TOKEN: '/v1/refreshToken'
    },	
    MAIN:{
        GET_PROFILE:'/v1/admin',
        GET_WHITELABEL_IMAGE:'/v1/whiteLabel/data',
        CHANGE_PASSWORD:'/v1/admin/password/change',
        GET_PLAYER_LIST:'/v1/player',
        GET_PLAYER_REPORT_WIN_LOSE:'/v1/player/reportWinLose',
        DELETE_PLAYER:'/v1/player',
        UPDATE_PLAYER:'/v1/player',
        GET_TRANSATION_HISTORY:'/v1/transaction',
        GET_PLAYER_LOGIN_HOSTORY:'/v1/player/loginHistory',
        GET_TARNSCTION_LIST:'/v1/transaction',
        REFRESH_GAME_LIST:'/v1/game/admin',
        UPDATE_TRANSACTION:'/v1/transaction',
        GLOBAL_SETTINGS: '/v1/global/setting',
        ACCOUNTS: '/v1/admin/bankAccount',
        FILE_UPLOAD: '/v1/file/upload',
        ADD_BANK_ACCOUNT:'/v1/admin/bankAccount',
        PLAYER_BANK_ACCOUNT:'/v1/player/bankAccount',
        GET_PLYER_GAMES:'/v1/player/gameHistory',
        GET_PLAYER_GAME_HISTORY:'/v1/player/userPlayHistory',
        CREATE_ADJUSTMENT:'/v1/transaction/adjustment',
        CONTACTS: '/v1/admin/contact',
        GET_LIST_OF_GAMES_FOR_PLAYER:'/v1/player/gameHistory',
        GET_USER_GAME_PLAY_HISTORY:'/v1/player/userPlayHistory',
        GET_BT2_GAME_HISTORY:'/v1/user/bt2GameHistory',
        GET_DASHBOARD_DATA:'/v1/user/dashboardData',
        GET_PLAYER_DASHBOARD_DATA:'/v1/player/dashboardData',
        GET_ADMIN_BANK_ACCOUNTS:'/v1/admin/bankAccount',
        CHANGE_USER_PASSWORD:'/v1/player',
        DOUBLE_MINI_GAME:'/v1/user/doubleMiniGame',
        //Account Type
        GET_ACCOUNT_TYPES:'/v1/account/type',
        CREATE_ACCOUNT_TYPE:'/v1/account/type',
        UPDATE_ACCOUNT_TYPE:'/v1/account/type/:id',
        DELETE_ACCOUNT_TYPE:'/v1/account/type/:id',
        //Account Brand type
         CREATE_BRAND_TYPE:'/v1/account/brand',
         GET_LIST_BRANDS:'/v1/account/brand',
         DELETE_BRAND:'/v1/account/brand/:id',
         UPDATE_BRAND:'/v1/account/brand/:id',
         //change logs

         GET_CHANGE_LOGS:'/v1/admin/changeLogs',

         //CMS
         BANNER: '/v1/popupBanner',
         GET_USER_ALL_TIME_DATA: '/v1/player/allTimeHistory',
         CREAT_PROMOTIONS:'/v1/promotions',
         GET_PERMOTIONS:'/v1/promotions',
         UPDATE_PERMOTIONS:'/v1/promotions',
         GET_SITE_DATA:'/v1/admin/site',
         PUT_SITE_DATA:'/v1/site',

         //PROVIDER & GAMES
         GET_PROVIDERS: '/v1/providers',
         GET_GAMES: '/v1/game',
         CHANGE_PROVIDER_STATUS: '/v1/provider',
         CHANGE_GAME_STATUS: '/v1/game',
         //Running Text
         CREATE_RUNNING_TEXT:'/v1/runningText',
         // MainBanner
         MAIN_BANNER:'/v1/mainBanner',
         //Footer
         FOOOTER:'/v1/footer',
         // Common API
         COMMON:'/v1/howItWorkSection',
         //role
        GET_ROLE_LIST:'/v1/role',
        CREATE_ROLE:'/v1/admin/subAdmin',
        GET_ADMIN_LIST:'/v1/admin/subAdmin',
        UPDATE_ADMIN:'/v1/admin/subAdmin',
        CREATE_NEW_ROLE:'/v1/role',
        UPDATE_ROLE:'/v1/role',
        GET_LIST_OF_RLES:'/v1/role',
        DELETE_ROLE:'/v1/role',
        DELETE_ADMIN:'/v1/admin/subAdmin',
        //bonus
        GET_GAME_CATEGORY_LIST:'/v1/bonus/settings',
        PUT_GAME_CATEGORY_LIST:'/v1/bonus/settings',
        GET_BONUS_SETTING:'/v1/bonus/transaction',
        PUT_BONUS_SETTING:'/v1/bonus/transaction'
    }
}

export const SIDEBAR =[
    {
        title:'Dashboard',
        icon:'assets/img/icons/menu_icons/status_icon.svg',
        path:`/main/${PATHS.MAIN.DASHBOARD}`,
        key:''
    },
    {
        title:'Players',
        icon:'assets/img/icons/menu_icons/users.svg',
        path:`/main/${PATHS.MAIN.PLAYERS}`,
        key:'canGetUserList'
    },
    {
        title:'Transaction',
        icon:'assets/img/icons/menu_icons/transaction.svg',
        path:`/main/${PATHS.MAIN.TRANSACTIONS}`,
        children:[
            { 
                title:'Deposit',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.DEPOSIT}`,
                key:''
            },
            {
                title:'Withdraw',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.WiITHDRAW}`,
                key:''
            },
            {
                title:'Adjustment',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.ADJUSTMENT}`,
                key:''
            },
            {
                title:'Referral Bonus',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.REFERRAL}`,
                key:''
            },
            {
                title:'Rollingan Bonus',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.ROLLINGAN}`,
                key:''
            },
            {
                title:'Cashback Bonus',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.CASHBACK}`,
                key:''
            },
            {
                title:'Deposit Bonus',
                path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.DEPOSIT_BONUS}`,
                key:''
            },
            // {
            //     title:'Reports',
            //     path:`/main/${PATHS.MAIN.TRANSACTIONS}/${PATHS.MAIN.REPORTS}`,
            // }
        ],
        key:'canGetTransactions'
    },
    {
        title:'Change Log',
        icon:'assets/img/icons/menu_icons/users.svg',
        path:`/main/${PATHS.MAIN.CHANGE_LOGS}`,
        key:'canGetChangeLogs'
    },
    {
        title:'RBAC',
        icon:'assets/img/icons/menu_icons/users.svg',
        path:`/main/${PATHS.MAIN.RBAC_MANAGEMENT}`,
        key:'canListAdmin'
    },
    {
        title:'Setting',
        icon:'assets/img/icons/menu_icons/settings.svg',
        path:`/main/${PATHS.MAIN.SETTING}`,
        children:[
            {
                title:'Games',
                path:`/main/${PATHS.MAIN.SETTING}/${PATHS.MAIN.GAME_SETTINGS}`,
                key:'canGetGames'
            },
            {
                title:'Finance',
                path:`/main/${PATHS.MAIN.SETTING}/${PATHS.MAIN.FINANCE}`,
                key:'canListFinance'
            },
            {
                title:'Contact',
                path:`/main/${PATHS.MAIN.SETTING}/${PATHS.MAIN.CONTACT}`,
                key:'canListContactPage'
            },
            {
                title: 'CMS',
                path:`/main/${PATHS.MAIN.SETTING}/${PATHS.MAIN.CMS}`,
                key:'canListCMS'
            },
            {
                title: 'Bonus',
                path:`/main/${PATHS.MAIN.SETTING}/${PATHS.MAIN.BONUS}`,
                key:'canListCMS'
            }
        ],
        key:'canViewSettings'
    },
    {
        title:'Reports',
        icon:'/assets/img/icons/menu_icons/reports_menu_icon.svg',
        path:`/main/${PATHS.MAIN.REPORT}`,
        children: [
            {
                title:'Game Win-Lose',
                path:`/main/${PATHS.MAIN.REPORT}/${PATHS.MAIN.GAME_WIN_LOSE}`,
                key:'canGetGames'
            },
            {
                title:'Player Win-Lose',
                path:`/main/${PATHS.MAIN.REPORT}/${PATHS.MAIN.PLAYER_WIN_LOSE}`,
                key:'canGetUserList'
            },
            // {
            //     title:'Player Report',
            //     path:`/main/${PATHS.MAIN.REPORT}/${PATHS.MAIN.PLAYER_REPORT}`
            // },
            {
                title:'Provider Win-Lose',
                path:`/main/${PATHS.MAIN.REPORT}/${PATHS.MAIN.PROVIDER_WIN_LOSE}`,
                key:'canGetGames'
            },
            {
                title:'Transaction Report',
                path:`/main/${PATHS.MAIN.REPORT}/${PATHS.MAIN.TRANSACTION_REPORT}`,
                key:'canGetTransactions'
            },
        ],
        key:'canViewReports'
    }
];

export const CONTACT_TYPE:any = {
  "PHONE": 1,
  "MESSAGE": 2,
  "LINE": 3,
  "WHATSAPP": 4,
  "WE_CHAT": 5,
  "TELEGRAM": 6,
  "TWITTER": 7,
  "FACEBOOK": 8,
  "INSTAGRAM": 9,
  "TIKTOK": 10
};
export const SOCKET_EVENTS = {
    ERROR: 'error',
    TEST: 'test',
    CONNECTION: 'connection',
    DISCONNECT: 'disconnect',
    TRANSACTION_COUNT: 'transactionCount',
    TRANSACTION_CREATED: 'transactionCreated',
    READ_TRANSACTION: 'readTransaction',
    TRANSACTION_INITIATE: 'transactionInitiate',
};
export const USER_TYPES = ['Bot','User','Admin']
