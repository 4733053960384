import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuradService implements CanActivate{
    constructor(private router:Router){}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(this.isAutharized()){
            this.router.navigateByUrl(`/main`)
        }
        return true;
    }

    isAutharized():boolean{
        let token = localStorage.getItem('token');
        if(token){
            return true;
        }
        return false;
    }
}