import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({ providedIn: 'root' })
export class HttpService {
  baseUrl = environment.baseURL;
  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    Accept: 'application/vnd.softswiss.v1+json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    'x-api-key': environment.API_KEY
  });
  get(url: string, data: any = {}) {
    if(data.isTrusted){
      delete data.isTrusted
    }
    
    Object.keys(data).forEach(key =>{
      if(!data[key]){
        if(typeof data[key] != 'boolean'){
          delete data[key]
        }
      }
    })
    if(data.page){
      delete data.page;
    }
    return this.http.get(`${this.baseUrl}${url}`, { params: data,headers:this.headers });
  }

  post(url: string, data: any) {
    Object.keys(data).forEach(key =>{
      if(!data[key]){
        delete data[key]
      }
    })
    return this.http.post(`${this.baseUrl}${url}`, data,{headers:this.headers});
  }

  delete(url: string, data: any) {
    Object.keys(data).forEach(key =>{
      if(!data[key]){
        delete data[key]
      }
    })
    return this.http.delete(`${this.baseUrl}${url}`, { body: data ,headers:this.headers});
  }

  put(url: string, data: any={},params:any={}) {
    Object.keys(data).forEach(key=>{
      if(data[key]=== undefined||data[key]===""){
       delete data[key];
      }
    })
    return this.http.put(`${this.baseUrl}${url}`, data,{headers:this.headers,params:params});
  }
  postFormData(apiPath: string, data?: any) {
    return this.http.post<any>(`${this.baseUrl}${apiPath}`, data, {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
      }),
    });
  }
}
