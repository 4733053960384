import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { SOCKET_EVENTS } from 'src/constants';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { StorageService } from './storage.service';
import { auto } from '@popperjs/core';
@Injectable({ providedIn: 'root' })
export class SocketService {
  socket: Socket | undefined;
  audioElement: HTMLAudioElement | null = null;
  private socketData = new Subject<any>();
  constructor(private storageService: StorageService, private alertService: AlertService) { }
  pendingCount = new BehaviorSubject<any>({});
  connect() {
    let token = this.storageService.getDataFromLocalstorage('token');
    this.socket = io(environment.soketUrl + `/?authorization=${token}`, {
      reconnection: true,
      reconnectionDelay: 500,
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      console.log('Socket Connected!'); // x8WIv7-
      this.getTransactionClount();
    });


    this.socket.on(SOCKET_EVENTS.TRANSACTION_INITIATE, (data) => {
      this.getTransactionClount();
    })
  }

  getSocketData() {
    return this.socketData.asObservable();
  }

  getTransactionClount() {
    this.socket && this.socket.emit(SOCKET_EVENTS.TRANSACTION_COUNT, {}, (responce: any) => {
      this.pendingCount.next(responce.data)
    })
  }

  emitSocket(eventName: string, data: any) {
    this.socket && this.socket.emit(eventName, data, (responce: any) => {
      console.log('Mardked as read.', responce);
      this.getTransactionClount();
    });
  }
  playNotification(source: any) {

    var mp3 = '<source src=' + `'${source}'` + '>';
    (document.getElementById('sound') as HTMLElement).innerHTML =
      '<audio >' + mp3 + '</audio>';
  }

  playNotificationV2(source: string) {
    if (!this.audioElement) {
      this.audioElement = document.createElement('audio');
      this.audioElement.id = 'notificationSound';
      this.audioElement.autoplay = true;
      this.audioElement.loop = true;
      document.body.appendChild(this.audioElement);
    }

    // Set the source
    this.audioElement.src = source;

    // Play only if there's a user interaction
    document.addEventListener('click', () => {
      if (this.audioElement && this.audioElement.paused) {
        this.audioElement.play().catch((error) => {
          console.error('Failed to play audio:', error);
        });
      }
    }, { once: true }); // Remove the listener after the first click
  }

  stopNotification() {
    if (this.audioElement) {
      // Pause and reset the audio
      this.audioElement.pause();
      this.audioElement.currentTime = 0;
    }
  }

  disconnect() {
    this.socket?.disconnect();
  }
}
